<template>
    <div class="no-modal">
        <div class="container-busqueda">
            <div style="width: 50%; display: flex; flex-direction: row; margin-top: 2%; margin-bottom: 3%;">
                <form class="input-group" @keyup="getCompany()">
                    <input type="search" class="form-control" v-model="search"
                        placeholder="Buscar por título de vacante, compañia/división..." />
                </form>
                <div class="dropdown" style="margin-left: 2%;">
                    <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Filtrar por Status
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" v-on:click="filterVacantesVigentes()">Vacantes Vigentes</a></li>
                        <li><a class="dropdown-item" v-on:click="filterVacantesCaducadas()">Vacantes Caducadas</a></li>
                        <li><a class="dropdown-item" v-on:click="filterVacantesTodas()">Todas</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-table">
            <table class="table  align-middle table-responsive table-hover caption-top">
                <thead>
                    <tr align="center" style="font-family: Poppins;">
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <!-- <th scope="col">Unidad/División</th> --> 
                        <th scope="col">Estado</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Status</th>
                        <th scope="col">Postulantes</th>
                        <!-- <th scope="col">Aceptados/Contratados</th> -->
                    </tr>
                </thead>
                <tbody v-if="response.loading">
                    <tr>
                        <td colspan="7">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else v-for="(item, i) in listVacantes" :key="i + 1" v-show="filter(item)">
                    <tr align="center" style="font-family: Poppins;">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.titulo }}</td>
                        <!-- <td>{{ item.dashboard.company }}</td> -->
                        <td>{{ item.estado }}</td>
                        <td>{{ item.type }}</td>
                        <td v-if="moment(item.createdAt) < this.vigencia" style="color: orangered;">
                            Vacante Caducada</td>
                        <td v-else style="color: green;">Vacante Vigente</td>
                        <td>
                            <div class="btn-margin-main">
                                <button class="btn-style" title="Ver Postulantes" v-show="(item.postulates > 0)"
                                    v-on:click="linkVacantePostulante(item._id)">
                                    <box-icon name='group' color="#03337A"></box-icon>
                                </button>
                            </div>
                        </td>
                        <!-- <td>
                            <div class="btn-margin-main">
                                <button class="btn-style" title="Ver Aceptados/Contratados" v-show="(item.postulates > 0)"
                                    v-on:click="linkVacante(item._id)">
                                    <box-icon name='check-shield' color="#03337A"></box-icon>
                                </button>
                            </div>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" v-on:click="getPreviousPage()">
                    <a class="page-link" href="#">Anterior</a>
                    </li>
                    <li v-for="(page, i) in totalPagesNumber" :key="i + 1" v-on:click="getDataPages(page)" v-bind:class="isActive(page)" class="page-item">
                    <a class="page-link" href="#">{{ page }}</a>
                    </li>
                    <li class="page-item" v-on:click="getNextPage()">
                    <a class="page-link" href="#">Siguiente</a>
                    </li>
                </ul>
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                        Página <b>{{ this.actual }}</b> de <b>{{ totalPagesNumber }}</b> - Total de <b>{{ this.listVacantes.length }}</b> vacantes disponibles
                    </li>
                </ul>
            </nav>
    </div>
</template>

<script lang="js">
import * as ClientService from "../../services/client"
import moment from "moment";
moment.locale("es");

export default {
    data: () => ({
        moment: moment,
        search: "",
        searchInput: "",
        vigencia: new Date(moment(new Date()).subtract('3', 'months').format('YYYY-MM-DD hh:mm:ss')),
        tabs: [
            {
                title: "Datos Personales",
                active: true,
                checked: true,
            },
            {
                title: "Datos Económicos",
                active: false,
                checked: false,
            },
        ],
        listVacantes: [],
        listVacantesTemp: [],
        response: {},
        elementSearch: "",
        elementPage: 10,
        dataPage: [],
        totalPagesNumber: 0,
        actual: 1,
        countElements: 0,
    }),
    mounted() {
        this.getCompany();
    },
    methods: {
        async getCompany() {
            try {
                this.response.loading = true;
                this.searchInput = this.search; // Actualizar searchInput
                let { data } = await ClientService.VacantesContributors({ search: this.search });
                this.listVacantes = data.data;
                this.listVacantesTemp = data.data;
                console.log(this.listVacantes);
                this.filterVacantesTFecha(this.listVacantes);
                this.response.loading = false;
                this.totalPages(this.listVacantes.length);
                this.listVacantes.length > 0 ? this.getDataPages(1) : this.response.loading = true;
            } catch (error) {
                this.response.loading = false;
            }
        },
        //TODO: Metodo Ordenar Vacantes fecha
        filterVacantesTFecha(vacantes) {
            vacantes.sort((a, b) => {
                if (a.createdAt == b.createdAt) {
                    return 0;
                }
                if (a.createdAt > b.createdAt) {
                    return -1;
                }
                return 1;
            });
            return vacantes;
        },
        //TODO: Filtrado O Busqueda Por Vacantes
        filter(vacante) {
            if (this.searchInput != "") {
                return vacante.titulo.toLowerCase().match(this.searchInput.toLocaleLowerCase()) || vacante.ubicacion.toLowerCase().match(this.searchInput.toLocaleLowerCase()) || vacante.modalidad.toLowerCase().match(this.searchInput.toLocaleLowerCase());
            }
            else {
                return vacante;
            }
        },
        linkVacante(id) {
            this.$router.push(`/administration/clients/vacante/${id}/becario`);
        },
        linkVacantePostulante(id){
            this.$router.push(`/client/vacante/${id}/postulantes`)
            //this.$router.push(`/vacante/${id}/postulantes`);
        },
        filterVacantesVigentes() {
            let vacantesfilter = this.listVacantesTemp.filter(vac => {
                return moment(vac.createdAt).isAfter(this.vigencia);
            });
            this.listVacantes = vacantesfilter;
            this.totalPages(vacantesfilter.length); 
            this.getDataPages(1); 
        },
        filterVacantesCaducadas() {
            let vacantesfilter = this.listVacantesTemp.filter(vac => {
                return moment(vac.createdAt).isBefore(this.vigencia);
            });
            this.listVacantes = vacantesfilter;
            this.totalPages(vacantesfilter.length); 
            this.getDataPages(1); 
        },
        filterVacantesTodas() {
            this.listVacantes = this.listVacantesTemp;
            this.totalPages(this.listVacantes.length);
            this.getDataPages(1); 
        },
        async totalPages(data) {
            console.log("Cantidad total de elementos:", data); // Verificar la cantidad total de elementos
            this.totalPagesNumber = Math.ceil(data / this.elementPage);
        },
        getDataPages(numberPage) {
            this.actual = numberPage;
            const start = (numberPage - 1) * this.elementPage;
            const end = Math.min(start + this.elementPage, this.listVacantes.length); // Asegura que end no sea mayor que la longitud de listVacantes
            const startIndex = (numberPage - 1) * this.elementPage; // Índice absoluto de inicio de la página
            this.dataPage = this.listVacantes.slice(start, end).map((item, index) => ({
                ...item,
                index: startIndex + index + 1
            }));
        },
        getPreviousPage() {
            if (this.actual > 1) {
                this.actual--;
            }
            this.getDataPages(this.actual);
        },
        getNextPage() {
            if (this.actual < this.totalPagesNumber) {
                this.actual++;
            }
            this.getDataPages(this.actual);
        },
        isActive(numberPage) {
            return numberPage == this.actual ? "active" : "";
        },
    },
};
</script>

<style land="scss" scoped>
@import "../../styles/talento.scss";
</style>